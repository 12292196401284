<template>
    <div>
        <div v-if="filteredData.length > 10" class=" hover:bg-gray-200 duration-300 ease inline-block px-3 py-2 rounded" @click="toggleExpanded">
            <div class="flex justify-start items-center gap-1 text-gray-700">
                <div class="font-bold text-[13px] cursor-pointer" >
                    {{ expanded ? 'Show less' : 'Show more' }}
                </div>
                <div ><mdicon :name=" expanded ? 'chevron-up':'chevron-down'" size="20"></mdicon></div>
            </div>
        </div>      
        <div>
            <div v-for="(item,index) in displayedData" :key="index">
                <div>
                    <v-list-tile class="group py-3 px-4 my-1 rounded-lg hover:bg-primary-100 duration-300 ease-out cursor-pointer" :class="!isRead(item) ? 'bg-primary-50' : ''" @click="readNotif(item)">
                        <template #leading>
                            <div class="w-14 h-14 overflow-hidden rounded-full">
                                <img v-if="item.source == null" :src="item.source" alt="" class="w-full h-full object-cover object-center">
                                <div v-if="item.source != null" class="w-full uppercase h-full bg-primary-500 text-white flex justify-center items-center text-[18px] tracking-widest">
                                    <template v-if="item.source !== 'Sistem'">
                                        {{ getInitials(item.employee.employee_name) }}
                                    </template>
                                    <template v-else>
                                        {{ getInitials(item.source) }}
                                    </template>
                                </div>
                            </div>  
                        </template>
                        <template #title v-if="item.title != null || item.title != ''">
                            <div class="text-[14px] font-normal mb-2 text-gray-700 tracking-wide">
                                {{ item.notification }}
                            </div>
                        </template>
                        <template #subtitle>
                            <div>
                                {{ item.date }}
                            </div>
                        </template>
                        <template #trailing>
                            <div class="flex justify-end items-end gap-3 h-[100%] mt-8 opacity-0 group-hover:opacity-100 px-5">
                                <v-tooltip>
                                    <template #trigger>
                                        <mdicon :name="!isRead(item) ? 'check-circle-outline' : 'checkbox-blank-circle-outline' " size="21" class="cursor-pointer  text-gray-500 hover:text-gray-700 duration-300 ease-in-out"  @click.stop  @click="isRead(item) ? unReadMark(item) : readMark(item)"></mdicon>
                                    </template>
                                    <template #content>
                                        <div class="whitespace-nowrap text-xs">
                                            {{ !isRead(item) ? 'Mark as read' : 'Mark as unread' }}
                                        </div>
                                    </template>
                                </v-tooltip>
                                <v-tooltip>
                                    <template #trigger>
                                        <mdicon name="trash-can-outline" size="21" class="cursor-pointer  text-gray-500 hover:text-gray-700 duration-300 ease-in-out"  @click.stop  @click="deleteNotif(item)"></mdicon>
                                    </template>
                                    <template #content>
                                        <div class="whitespace-nowrap text-xs">
                                            Delete notification
                                        </div>
                                    </template>
                                </v-tooltip>
                            </div>
                        </template>
                    </v-list-tile>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    name: 'PadmaErpSevenDayNotif',
    props: ['data'],
    data() {
        return {
            expanded : false,
        };
    },

    mounted() {
        
    },

    methods: {
        isRead(item){
            let isRead = false;
            let employeeId = this.loggedInUser.employee_id
            if(item.assign == employeeId && item.read == '1' || item.assign == employeeId && item.read == 1){
                isRead = true;
            } else {
                let mentions = item.mentions;

                if(mentions != null){
                    mentions.forEach(element => {
                        if(element.read && element.id == employeeId){
                            isRead = true;
                        }   
                    });
                }
            }

            return isRead;
        },

        readNotif(item){
            if (!item.external_url || item.external_url == '0') {
                this.$router.push({ path: item.url })
            } else {
                window.open(item.url, '_blank');
            }

            
            let formData = new FormData();
            formData.append('id',item.id); 
            formData.append('employee_id',this.loggedInUser.employee_id);         

            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post(`api/notification/read`,formData, { headers : headers }).then(()=>{
                this.getNotif();
            })
            this.$emit('close')
        },

        readMark(item){
            let formData = new FormData();
            formData.append('id',item.id); 
            formData.append('employee_id',this.loggedInUser.employee_id);         

            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post(`api/notification/read`,formData, { headers : headers}).then(()=>{
                this.getNotif();
            })
        },
        
        unReadMark(item){
            let formData = new FormData();
            formData.append('id',item.id); 
            formData.append('employee_id',this.loggedInUser.employee_id);         

            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post(`api/notification/unread`,formData, { headers : headers}).then(()=>{
                this.getNotif();
            })
        },

        deleteNotif(item){
            let formData = new FormData();
            formData.append('id',item.id); 
            formData.append('employee_id',this.loggedInUser.employee_id);         

            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post(`api/notification/delete`,formData, { headers : headers}).then(()=>{
                this.getNotif();
            })
        },

        getNotif(){
            this.loadingdata = true;
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/notification/get/${this.loggedInUser.employee_id}`, { headers : headers }).then((response)=>{
                const newNotifications = response.data;
                store.commit('update_notification', newNotifications);
            }).catch((error)=>{
                
            }).finally(() => {
                this.loadingdata = false;
            })
                    
        },


        toggleExpanded() {
            this.expanded = !this.expanded;
        },
    },
    computed : {
        filteredData() {
            return this.data;
        },
        displayedData() {
        // Tampilkan 2 item teratas jika tidak diperluas, atau semua jika diperluas
             return this.expanded ? this.filteredData : this.filteredData.slice(0, 10);
        },
    }
};
</script>

<style lang="scss" scoped>

</style>