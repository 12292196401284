import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
import axios from 'axios';

// Fungsi untuk memeriksa apakah pengguna telah login
function isLoggedIn() {
  if (localStorage.length > 0) {
    const session = ls.get('erp-session');
    if (session && session.data && session.data.user) {
      return true;
    }
  }
  return false;
}

// Ambil nilai erpTimeout dari localstorage
let erpTimeout = parseInt(ls.get('erpTimeout')) || 0; // Default 20 menit jika erpTimeout tidak tersedia
// Variabel untuk menyimpan ID timeout
let timeoutID;

// Fungsi untuk mengatur lastActivity di localstorage dengan waktu saat ini, tetapi hanya jika belum melewati timeout dan pengguna telah login
function setLastActivity() {
  if (isLoggedIn()) {
    const lastActivity = ls.get('lastActivity');
    if (!lastActivity || checkElapsedTime(lastActivity) < erpTimeout) {
      ls.set('lastActivity', new Date().getTime());
    }
  }
}

// Fungsi untuk memeriksa elapsed time sejak lastActivity
function checkElapsedTime(lastActivity) {
  const currentTime = new Date().getTime();
  return (currentTime - parseInt(lastActivity)) / 60000; // Konversi dari milidetik ke menit
}

// Fungsi untuk mengecek apakah sudah melebihi timeout
function checkTimeout() {
  const lastActivity = ls.get('lastActivity');
  if (lastActivity && erpTimeout !== 0) {
    const elapsedTime = checkElapsedTime(lastActivity);
    if (elapsedTime >= erpTimeout && isLoggedIn()) {
      autologout(); // Lakukan autologout jika sudah melebihi timeout dan pengguna telah login
    }
  }
}

function token() {
  if (localStorage.length > 0) {
    let session = ls.get('erp-session');
    if (session && session.data && session.data.token) {
      return session.data.token;
    }
  }
  return false;
}

function autologout() {

  const apiKey = process.env.VUE_APP_API_KEY;
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'X-API-KEY': apiKey,
    'Authorization': `Bearer ${token()}`
  }

  axios.post('/api/auto-logout',{},{ headers : headers})
    .then(() => {
      window.location.href = "/";
      localStorage.clear();
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

// Fungsi untuk mengatur timeout setelah 20 menit dari lastActivity
function startTimeout() {
  if (erpTimeout !== 0) {
    timeoutID = setTimeout(() => {
      autologout();
    }, erpTimeout * 60000); // Konversi dari menit ke milidetik
  }
}

// Panggil fungsi startTimeout setelah setiap aktivitas
function handleActivity() {
  setLastActivity();
  checkTimeout();
  resetTimeout(); // Set ulang timeout setelah setiap aktivitas
}

// Fungsi untuk menangani event mouse click
export function handleMouseClick(event) {
  if (isLoggedIn()) {
    handleActivity(); // Panggil handleActivity setelah mouse click jika pengguna telah login
  }
}

// Fungsi untuk menangani event keyboard click
export function handleKeyboardClick(event) {
  if (isLoggedIn()) {
    handleActivity(); // Panggil handleActivity setelah keyboard click jika pengguna telah login
  }
}

// Fungsi untuk menangani event mounted aplikasi Vue
export function handleAppMounted() {
  if (isLoggedIn()) {
    handleActivity(); // Panggil handleActivity setelah aplikasi Vue mounted jika pengguna telah login
    resetTimeout(); // Set ulang timeout saat aplikasi Vue mounted
  }
}

// Fungsi untuk set ulang timeout
function resetTimeout() {
  clearTimeout(timeoutID); // Hentikan timeout yang sedang berjalan
  startTimeout(); // Mulai timeout dari awal lagi
}

// Fungsi untuk mengatur ulang timeout setelah mendapatkan perubahan di localStorage
function syncTimeout(event) {
  if (event.key === 'lastActivity' && isLoggedIn()) {
    resetTimeout();
  }
}

// Menambahkan event listener untuk sinkronisasi antar tab
window.addEventListener('storage', syncTimeout);

// Menambahkan event listener secara global
document.addEventListener('click', handleMouseClick);
document.addEventListener('keydown', handleKeyboardClick);
document.addEventListener('DOMContentLoaded', handleAppMounted);

