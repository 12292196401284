<template>
    <div>
        <v-loading v-if="isloading"></v-loading>
        <div class="w-[90vw] rounded-lg bg-white h-[100vh] overflow-hidden" v-if="data != null">          
          <div class="w-full h-[40%] md:h-[45%] bg-primary-500 flex flex-col gap-2 relative">
            <div class="absolute right-4 top-4">
                <mdicon name="close" size="25" class="text-white cursor-pointer" @click="$emit('close')"></mdicon>
            </div>
            <div class="w-full h-full flex justify-center flex-col gap-4 items-center pt-2 md:pt-4">
              <div class="w-[80px] h-[80px] md:w-[120px] md:h-[120px] rounded-full overflow-hidden">
                <img v-if="data.photo_url != null" :src="data.photo_url" class="w-full h-full object-cover object-center" alt="">
                <div v-if="data.photo_url == null" class="w-full h-full bg-primary-700 text-white flex justify-center items-center text-xs tracking-widest font-bold">
                  {{ getInitials(data.employee_name) }}
                </div>
              </div>
              <div class="flex flex-col justify-center items-center gap-2">
                <div class="text-[18px] md:text-[25px] font-light text-white">
                  {{ data.employee_name }}
                </div>
                <div class="bg-gray-50 rounded inline-block px-2 py-1 text-[10px] md:text-[12px] text-center bg-opacity-70 text-gray-700">{{ data.job_positions.job_position }}</div>
              </div>
            </div>
            <div>
                <div class="px-2 visible md:hidden mb-2">
                    <v-select :disabledOnSelect="true" :options="tabs" v-model="selected_tab" placeholder="Select Tab"></v-select>
                </div>
              <div class="hidden md:flex justify-center overflow-x-auto text-[14px] tracking-wide whitespace-nowrap font-light text-white items-start gap-6 border-b w-full">
                <div v-for="(tab, i) in tabs" :key="i" :class="[
                  'h-full px-2 pb-2 cursor-pointer tab-label text-center',
                  tab.value === selected_tab ? 'tab-label-active' : '', // Atur garis bawah
                ]" @click="updatetab(tab.value)">
                  {{ tab.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center items-start overflow-y-scroll h-[60%] border">
            <div class="w-[90%] md:w-[45%] py-4 md:py-8">
              <slotComponent v-if="selected_tab == 1"><generalVue :data="data"></generalVue></slotComponent>
              <slotComponent v-if="selected_tab == 2"><employmentVue :data="data"></employmentVue></slotComponent>
              <slotComponent v-if="selected_tab == 3"><familyVue :data="data"></familyVue></slotComponent>
              <slotComponent v-if="selected_tab == 4"><educationVue :data="data"></educationVue></slotComponent>
              <slotComponent v-if="selected_tab == 5"><customeVue :data="data"></customeVue></slotComponent>
              <slotComponent v-if="selected_tab == 6"><financeVue :data="data"></financeVue></slotComponent>
              <slotComponent v-if="selected_tab == 7"><documenVue :data="data"></documenVue></slotComponent>
              <slotComponent v-if="selected_tab == 8"><userVue :data="userdata"></userVue></slotComponent>
            </div>
          </div>
        </div>
    </div>
  </template>

<script>
import userVue from './user.vue'
import documenVue from './document.vue'
import customeVue from './custome.vue'
import educationVue from './education.vue'
import generalVue from './general.vue'
import employmentVue from './employment.vue'
import slotComponent from './slot.vue'
import familyVue from './family.vue'
import financeVue from './finance.vue'
export default {
    name: 'PadmaErpMyProfile',

    data() {
        return {
            isloading : false,
            selected_tab : 1,
            tabs : [
                {
                    label : 'General',
                    value : 1
                },
                {
                    label : 'Employment',
                    value : 2
                },
                {
                    label : 'Family',
                    value : 3
                }
                ,
                {
                    label : 'Education',
                    value : 4
                },
                {
                    label : 'Custome Field',
                    value : 5
                },
                {
                    label : 'Finance',
                    value : 6
                },
                {
                    label : 'Document',
                    value : 7
                },
                {
                    label : 'Account',
                    value : 8
                }
            ],
            data: null,
            userdata: null
        };
    },

    mounted() {
        this.getdata();   
        this.getUser();    
    },

    methods: {
        updatetab(val){
            this.selected_tab = val
        },

        getdata(){
            this.isloading = true
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/employee/${this.loggedInUser.employee.id}/detail`, { headers : headers }).then((response)=>{
                this.data = response.data
               
            }).catch((error)=>{
              
            }).finally(()=>{
                this.isloading = false
            })
        },

        getUser(){
           
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/user/${this.loggedInUser.employee.id}/detail`, { headers : headers }).then((response)=>{
                this.userdata = response.data               
            }).catch((error)=>{
              
            })
        },

        getInitials(name) {
            if (!name || name.trim() === "") {
                return "";
            }

            let names = name.split(" ");
            let initials = "";
            let numWords = 2;

            if (names.length < numWords) {
                initials = name[0] + name[1];
            } else {
                for (var i = 0; i < numWords; i++) {
                    initials += names[i][0];
                }
            }
            return initials;
        },
    },

    components : {
        slotComponent,
        generalVue,
        employmentVue,
        familyVue,
        educationVue,
        customeVue,
        financeVue,
        documenVue,
        userVue
    },

    computed : {
       
    }
};
</script>

<style lang="scss" scoped>
.tab-label {
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}

.tab-label::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: white;
  transition: 0.3s;
  transform: translateX(-50%);
  @apply rounded-full;
}

.tab-label:hover::before,
.tab-label-active::before {
  width: 100%;
}

::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}


::-webkit-scrollbar-track {
    @apply bg-gray-300 bg-opacity-30;
// background: #e5e5e5; 
}

/* Handle */
::-webkit-scrollbar-thumb {
 @apply bg-gray-300 bg-opacity-50;
// background: rgb(185, 185, 185); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300 bg-opacity-100;
// background: rgb(182, 182, 182); 
}
</style>