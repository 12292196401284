import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import common from '@/plugin/common'
import * as globalEventHandlers from '@/plugin/globalEventHandlers.js';

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueNumberFormat from 'vue-number-format'
import VueMask from '@devindex/vue-mask';
import { vue3Debounce } from 'vue-debounce'
import Notifications from '@kyvg/vue3-notification'

import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import vRadio from '@/layout/component/v-radio.vue';
import vSelect from '@/layout/component/v-select.vue';
import vCard from '@/layout/component/v-card.vue';
import vDialog from '@/layout/component/v-dialog.vue';

import dataTable from '@/layout/component/data-table.vue';
import vBtn from '@/layout/component/v-btn.vue';
import vAlert from '@/layout/component/v-alert.vue';
import vRow from '@/layout/component/v-row.vue';
import vCol from '@/layout/component/v-col.vue';
import vTextField from '@/layout/component/v-text-field.vue';
import vCheckbox from '@/layout/component/v-checkbox.vue';
import vLoading from '@/layout/component/v-loading.vue';
import vChip from '@/layout/component/v-chip.vue';
import vListTile from  '@/layout/component/v-list-tile.vue';
import vTextarea from  '@/layout/component/v-textarea.vue';
import vTooltip from  '@/layout/component/v-tooltip.vue';
import PopUpMenu from  '@/layout/component/pop-up-menu.vue';
import vStatus from  '@/layout/component/v-status.vue';
import vMention from '@/layout/component/v-mention.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import VueSignaturePad from 'vue-signature-pad';


const app = createApp(App)
axios.defaults.baseURL = process.env.VUE_APP_API_URL
app.config.globalProperties.$apiKey = process.env.VUE_APP_API_KEY;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

app.mixin(common)
app.use(store)
app.use(VueMask)
app.use(router)
app.use(VueNumberFormat)
app.use(VueAxios,axios)
app.use(mdiVue, {
    icons: mdijs
}).directive('debounce', vue3Debounce({ lock: true }))
app.component('vRadio',vRadio)
app.component('vSelect',vSelect)
app.component('vCard',vCard)
app.component('vDialog',vDialog)
app.component('dataTable',dataTable)
app.component('vBtn',vBtn)
app.component('vAlert',vAlert)
app.component('vRow',vRow)
app.component('vCol',vCol)
app.component('vTextField',vTextField)
app.component('vCheckbox',vCheckbox)
app.component('vLoading',vLoading)
app.component('vChip',vChip)
app.component('vListTile',vListTile)
app.component('vTextarea',vTextarea)
app.component('vTooltip',vTooltip)
app.component('PopUpMenu',PopUpMenu)
app.component('vStatus',vStatus)
app.component('vMention',vMention)
app.component('VueDatePicker', VueDatePicker)
app.use(Notifications).use(VueSignaturePad)
app.config.globalProperties.$globalEventHandlers = globalEventHandlers;
app.mount('#app');
