<template>
    <div class="px-4 py-6 h-[90vh] overflow-y-auto">
        <div v-if="messages.length == 0" class="flex flex-col justify-start gap-4 items-center mt-[55px]">
            <div class="w-[45%]">
                <img :src="nodataImage" alt="No Data Image" class="w-full h-full">
            </div>
            <div class="text-3xl font-bold text-gray-800">
                {{ textBold }}
            </div>
        </div>
        <div v-if="messages.length > 0">
            <div v-for="(message,index) in messages" :key="index" class="mb-4">
                <messageItem :message="message" :messageIndex="index" type="outbox" @close="updateData" @update="getMessage"></messageItem>
            </div>
        </div>
    </div>
</template>

<script>
import noData from '@/assets/outbox-no-data.svg'
import messageItem from "./components/message-item.vue"
export default {
    name: 'PadmaErpOutbox',

    data() {
        return {
            nodataImage : noData,
            messages : []
        };
    },

    mounted() {
        this.getMessage();
    },

    methods: {
        updateData(val){
            if(val){
                this.getMessage();
            }
        },
        getMessage(){
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.get(`api/message/${this.loggedInUser.username}/outbox`, { headers : headers}).then((response)=>{
               this.messages = response.data;
            }).catch((error)=>{
            })
        },
    },
    components : {
        messageItem
    },
    computed : {
        textBold (){
            return 'No outbox updates'            
        },

    }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    height: 7px;
    width: 7px;
}


::-webkit-scrollbar-track {
    @apply bg-gray-500 bg-opacity-30;
// background: #e5e5e5; 
}

/* Handle */
::-webkit-scrollbar-thumb {
 @apply bg-gray-500 bg-opacity-50;
// background: rgb(185, 185, 185); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500 bg-opacity-100;
// background: rgb(182, 182, 182); 
}
</style>