<template>
      <div class="px-4 flex justify-start items-center gap-2 relative">
            <input type="text" placeholder="Write Repy...." ref="textComment" class="w-full border border-gray-300 rounded-full focus:outline-none py-2 px-4 text-sm"  v-debounce:500ms="handleInput" @keydown="handleKeyDown"  name="" id="">
            <v-btn type="primary" append="send-variant-outline" class="rounded-full" @click="comment"></v-btn>
            <div v-if="isshowSuggestions" ref="suggestionsList" class="suggestions rounded max-h-[200px] overflow-y-auto absolute shadow bg-white">
                <div v-for="(mention,indexMention) in mentions" :key="indexMention" @click="selectMention(mention.username)" class="w-full cursor-pointer hover:bg-gray-200 duration-300 ease px-4 py-1">
                    <v-list-tile>
                        <template #leading>
                            <div class="w-7 h-7 overflow-hidden rounded-full">
                                <img v-if="mention.photo_url != null" :src="mention.photo_url" alt="" class="w-full h-full object-cover object-center">
                                <div v-if="mention.photo_url == null" class="w-full h-full bg-primary-500 text-white flex justify-center items-center text-xs tracking-widest font-bold">
                                    {{ getInitials(mention.employee_name) }}
                                </div>
                            </div>  
                        </template>
                        <template #title>
                            <div class="text-[12px]">
                                {{ mention.employee_name }}
                            </div>
                        </template>
                        <template #subtitle>
                            <div class="text-[11px]">
                                {{ mention.username }}
                            </div>
                        </template>
                    </v-list-tile> 
                </div>
            </div>        
        </div>
</template>

<script>
export default {
    name: 'PadmaErpCommentInput',
    props : ['id'],
    data() {
        return {
            mentions : [],
            lastWord : null,
            isshowSuggestions : false
        };
    },

    mounted() {
        
    },

    methods: {
        handleInput(event) {
            this.mentions = [];
            this.lastWord = null;
            const textComment = this.$refs.textComment;
            const cursorPos = textComment.selectionStart;

            const textBeforeCursor = textComment.value.substring(0, cursorPos);
            const regex = /(?:^|\s)@(\w*)$/;
            const match = textBeforeCursor.match(regex);
            
            if (match) {
                this.lastWord = match[1]; // Simpan kata yang diawali dengan "@" terakhir
                this.searchMention(this.lastWord)


                this.isshowSuggestions = true;

                const cursorCoordinates = this.getCursorCoordinates(textComment, cursorPos);
                const { top, left } = cursorCoordinates;

                this.$nextTick(() => {
                    this.$refs.suggestionsList.style.top = top + 'px';
                    this.$refs.suggestionsList.style.left = left + 'px';
                });
                
                
            } else {
                const newMatch = textComment.value.match(regex);
                if (!newMatch || newMatch[1] !== this.lastWord) {
                    this.isshowSuggestions = false;
                }
            }
        },


        searchMention(text){
            this.mentions = [];    
            let formData = new FormData();
            formData.append('mention_text', text);

            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/message/select_mention',formData, { headers : headers }).then((response)=>{
                let resData = response.data;
                resData.forEach(element => {
                    let obj = {
                        username : element.username,
                        employee_name : element.employee.employee_name,
                        email : element.employee.email,
                        photo_url : element.employee.photo_url
                    }

                    this.mentions.push(obj);
                });
            })
        },

        getCursorCoordinates(textComment, cursorPos) {
            const lineHeight = parseFloat(window.getComputedStyle(textComment).lineHeight);

            // Calculate the number of lines up to the cursor position
            const linesBeforeCursor = textComment.value.substr(0, cursorPos).split('\n').length - 1;
            
            // Calculate the width of the text on the current line
            const dummyDiv = document.createElement('div');
            dummyDiv.style.position = 'absolute';
            dummyDiv.style.whiteSpace = 'pre-wrap';
            dummyDiv.innerText = textComment.value.split('\n')[linesBeforeCursor];
            
            document.body.appendChild(dummyDiv);
            const textBoundingRect = dummyDiv.getBoundingClientRect();
            document.body.removeChild(dummyDiv);

            const { left } = textComment.getBoundingClientRect();
            const cursorX = (left + textBoundingRect.width)/6;
            const cursorY = linesBeforeCursor * lineHeight - 195;

            return {
                top: cursorY,
                left: cursorX
            };
        },

        selectMention(mention){
            const textComment = this.$refs.textComment;
            const cursorPos = textComment.selectionStart;

            // Regular expression untuk menemukan kata terakhir yang diawali dengan "@"
            const regex = /(^|\s)@(\w*)$/;
            let text = textComment.value;

            // Temukan kata terakhir yang diawali dengan "@"
            const match = text.match(regex);

            if (match) {
            // Ganti kata terakhir dengan `mention`, termasuk karakter "@"
                text = text.replace(regex, `$1@${mention}`);
                
                // Update nilai textarea
                textComment.value = text;

                // Atur posisi kursor setelah mention
                textComment.setSelectionRange(cursorPos, cursorPos + mention.length);

                // Tutup suggestions setelah memilih mention
                this.isshowSuggestions = false;
            }
        },

        comment(){
            const textCommentValue = this.$refs.textComment;
            let comment = textCommentValue.value;

            this.isloading = true;
            let formData = new FormData();
            formData.append('username', this.loggedInUser.username);
            formData.append('comment', comment);
            formData.append('message_id', this.id);
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/message/comment',formData, { headers : headers }).then((response)=>{
                this.commentText = '';
                this.$emit('update');
            }).catch((error)=>{
            }).finally(()=>{
                this.isloading = false;
            });
            },
    },
};
</script>

<style lang="scss" scoped>

</style>