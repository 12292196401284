<template>
    <div class="w-[70%]">
        <div>
            <v-list-tile>
                <template #leading>
                    <div class="w-10 h-10 overflow-hidden rounded-full">
                        <img v-if="comment.photo_url != null" :src="comment.photo_url" alt="" class="w-full h-full object-cover object-center">
                        <div v-if="comment.photo_url == null" class="w-full h-full bg-primary-500 text-white flex justify-center items-center text-[12px] tracking-widest font-bold">
                            {{ getInitials(comment.employee_name) }}
                        </div>
                    </div>  
                </template>
                <template #title>
                    <div class="flex justify-start items-center gap-2">
                        <div class="text-[13px] font-normal bg-gray-100 min-w-[150px] p-2 leading-relaxed rounded-lg">
                            <div v-html="comment.comment">
                            </div>                            
                        </div>
                        <div v-if="is_deletable">
                            <mdicon name="close" size="15" class="cursor-pointer text-gray-400 duration-300 ease-in-out hover:text-gray-700" @click="delete_comment"></mdicon>
                        </div>
                    </div>
                </template>
            </v-list-tile> 
            <div class="flex justify-start items-center gap-1 text-[12px] py-2 px-14">
                <div>
                    <mdicon name="clock-outline" size="18" class="text-gray-500"></mdicon>
                </div>
                <div>
                    {{ moment(comment.timestamp).format('lll') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: 'PadmaErpMessageComment',
    props : ['comment','message_id'],
    data() {
        return {
            moment : moment,           
        };
    },

    mounted() {
        
    },

    methods: {
        delete_comment(){
            this.isloading = true;
            let formData = new FormData();
            formData.append('username', this.loggedInUser.username);
            formData.append('comment_id', this.comment.comment_id);
            formData.append('message_id', this.message_id);
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/message/comment/delete',formData, { headers : headers }).then((response)=>{
                this.$emit('update');
            }).catch((error)=>{
            }).finally(()=>{
                this.isloading = false;
            });
        },
    },

    computed: {
        is_deletable(){
            return this.comment.username == this.loggedInUser.username
        }
    }
};
</script>

<style lang="scss" scoped>

</style>