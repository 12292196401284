<template>
    <div class="w-[80%]">
        <div class="flex justify-start items-start gap-2 group ">
            <v-card class="hover:shadow-lg w-full">
                <template #card-title>
                    <div @click="toggleExpaned" class="cursor-pointer">
                        <v-list-tile>
                            <template #leading>
                                <div class="w-10 h-10 overflow-hidden rounded-full">
                                    <img v-if="message.sender.photo_url != null" :src="message.sender.photo_url" alt="" class="w-full h-full object-cover object-center">
                                    <div v-if="message.sender.photo_url == null" class="w-full h-full bg-primary-500 text-white flex justify-center items-center text-xs tracking-widest font-bold">
                                        {{ getInitials(message.sender.employee_name) }}
                                    </div>
                                </div>  
                            </template>
                            <template #title>
                                <div class="text-[16px] font-normal">
                                    {{ message.sender.employee_name }} <span class="text-xs">&lt; {{ message.sender.username }} &gt;</span>
                                </div>
                            </template>
                            <template #subtitle>
                                <div class="flex justify-start items-center gap-1 text-[13px]">
                                    <div>to:</div>
                                    <div class="flex items-center gap-1 justify-start">
                                        <div v-for="(item,index) in showReciever" :key="index" class="items-center">
                                            <div>
                                                {{ item.username }} <span v-if="index < showReciever.length-1">,</span>
                                            </div>
                                        </div>
                                        <div class="relative">
                                            <div class="p-[0.5px] rounded-sm hover:bg-gray-200 duration-300 ease-in-out" @click.stop @click="detailExpanded = !detailExpanded">
                                                <mdicon name="menu-down" size="20" class="text-gray-600"></mdicon>
                                            </div>
                                            <div class="absolute left-[10%] to clear-left bg-white shadow p-2" v-if="detailExpanded">
                                                <div class="grid grid-cols-[40px_1fr] justify-start items-start mb-[2px] gap-2">
                                                    <div class="text-end">from: </div>
                                                    <div class="whitespace-nowrap"> {{ message.sender.employee_name }} <span class="text-xs">&lt; {{ message.sender.username }} &gt;</span></div>
                                                </div>
                                                <div class="grid grid-cols-[40px_1fr] justify-start items-start mb-[2px] gap-2">
                                                    <div class="text-end">to: </div>
                                                    <div>
                                                        <div class="whitespace-nowrap" v-for="(item,index) in message.receivers" :key="index"> {{ item.employee_name }} <span class="text-xs">&lt; {{ item.username }} &gt;</span><span v-if="index < message.receivers.length-1">,</span></div>
                                                    </div>
                                                </div>
                                                <div class="grid grid-cols-[40px_1fr] justify-start items-start mb-[2px] gap-2">
                                                    <div class="text-end">date: </div>
                                                    <div>
                                                        <div class="whitespace-nowrap"> {{ moment(message.timestamp).format('LLL') }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </v-list-tile>    
                    </div>
                </template>
                <template #card-body>
                    <div :class="!isExpanded ? 'h-[40px] overflow-hidden duration-300 ease-in-out' : ' h-auto'">                   
                        <div class="text-[16px] text-[#323338] mt-4 leading-relaxed">
                            <div class="content text-[14px] leading-relaxed" v-html="message.content"></div>
                        </div>    
                        <div class="mt-4" v-if="message.replies.length">
                            <div v-for="(cm,index) in message.replies" :key="index" class="mb-4">
                                <messageComment :comment="cm" :message_id="message.id" @update="$emit('update')"></messageComment>
                            </div>
                        </div>
                        <div class="mt-4" v-if="type != 'delete'">
                            <commentInput :id="message.id" @update="$emit('update')"></commentInput>
                        </div>                      
                    </div>
                </template>
                <template #card-action>
                    <div class="w-full" v-if="!isExpanded">
                        <div class="text-primary-400 text-[13px] tracking-wider text-center cursor-pointer" @click="toggleExpaned">Show More</div>
                    </div>
                </template>
            </v-card>
            <div class="flex flex-col gap-3 justify-start items-center">
                <div :class="isRead ? 'h-[27px] bg-gray-200 text-gray-400 cursor-pointer group-hover:text-gray-500 duration-300 ease-in-out' : 'h-[27px] bg-primary-500 text-white'" class="w-[27px]  rounded flex justify-center items-center cursor-pointer hover:shadow-lg duration-300 ease-in-out active:translate-y-1" @click="changeStatus" v-if="needUpdate">
                    <mdicon v-if="isRead" name='dots-horizontal' size="22"></mdicon>
                    <mdicon v-if="!isRead" name='check' size="22"></mdicon>
                </div>

                <div class="h-[27px] w-[27px] rounded flex justify-center items-center cursor-pointer hover:shadow-lg duration-300 ease-in-out active:translate-y-1" :class="isBookmarked ? 'bg-primary-500' : 'bg-gray-200'" @click="changeBookmark" v-if="type != 'delete'">
                    <mdicon name="bookmark-outline" size="22" :class="isBookmarked ? 'text-white' : 'text-gray-500'"></mdicon>
                </div >
                <div class="h-[27px] w-[27px] rounded flex justify-center items-center bg-gray-200 group cursor-pointer hover:shadow-lg duration-300 ease-in-out active:translate-y-1" @click="delete_data">
                    <mdicon v-if="type == 'delete'" name="file-restore-outline" class="text-gray-500 group-hover:text-gray-700 duration-300 ease-in-out" size="22"></mdicon>
                    <mdicon v-if="type != 'delete'" name="trash-can-outline" class="text-gray-500 group-hover:text-gray-700 duration-300 ease-in-out" size="22"></mdicon>
                </div>
                <div v-if="type == 'delete'" class="h-[27px] w-[27px] rounded flex justify-center items-center bg-gray-200 group cursor-pointer hover:shadow-lg duration-300 ease-in-out active:translate-y-1" @click="permanent_delete">
                    <mdicon name="delete-forever" class="text-gray-500 group-hover:text-gray-700 duration-300 ease-in-out" size="22"></mdicon>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import commentInput from './commentInput.vue'
import messageComment from './message-comment.vue'
import store from '@/store';
import moment from 'moment';
export default {
    name: 'PadmaErpMessageItem',
    props : ['message', 'messageIndex','type'],
    data() {
        return {
            moment : moment,
            isExpanded : false,
            detailExpanded : false,
        };
    },

    mounted() {
        
    },

    methods: {
        toggleExpaned(){
            if(!this.isExpanded) {
                this.isExpanded = true;
            } else {
                this.isExpanded = false;
                this.detailExpanded = false;
            }
        },
        changeStatus(){
            if(this.needUpdate){
                let formData = new FormData();
                formData.append('id', this.message.id);
                formData.append('username', this.loggedInUser.username);
                const headers = {
                    'Content-Type' : 'application/json',
                    'Access-Control-Allow-Origin' : '*',
                    'Accept' : 'application/json', 
                    'X-API-KEY': this.$apiKey // Ambil dari .env

                }
                this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
                this.axios.post(`api/message/change_status`,formData, { headers : headers }).then(()=>{
                   this.$emit('close', true);

                   this.getUnread();
                }).catch((error)=>{
                })
            }

        },
        changeBookmark(){           
            let formData = new FormData();
            formData.append('id', this.message.id);
            formData.append('username', this.loggedInUser.username);
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post(`api/message/change_bookmark`,formData, { headers : headers }).then(()=>{
                this.$emit('close', true);
            }).catch((error)=>{
            })           
        },

        delete_data(){           
            let formData = new FormData();
            formData.append('id', this.message.id);
            formData.append('username', this.loggedInUser.username);
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post(`api/message/delete_data`,formData, { headers : headers }).then(()=>{
                this.$emit('close', true);
                this.getUnread();
            }).catch((error)=>{
            })           
        },
        permanent_delete(){           
            let formData = new FormData();
            formData.append('id', this.message.id);
            formData.append('username', this.loggedInUser.username);
            const headers = {
                'Content-Type' : 'application/json',
                'Access-Control-Allow-Origin' : '*',
                'Accept' : 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post(`api/message/permanent_delete`,formData, { headers : headers }).then(()=>{
                this.$emit('close', true);
                this.getUnread();
            }).catch((error)=>{
            })           
        },
        getUnread(){
                const headers = {
                    'Content-Type' : 'application/json',
                    'Access-Control-Allow-Origin' : '*',
                    'Accept' : 'application/json',
                    'X-API-KEY': this.$apiKey // Ambil dari .env

                }
                this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
                this.axios.get(`api/message/${this.loggedInUser.username}/unread`, { headers : headers }).then((response)=>{
                    const messages = response.data;
                    store.commit('update_messages', messages);
                }).catch((error)=>{
                })
            },
        },

    computed: {
        showReciever(){
            return this.message.receivers.slice(0,2);
        },

        // isBookmarked(){

        // },

        needUpdate(){
            let status = true;

            if(this.type == 'outbox'){
                status = false;
            } else  if(this.type == 'bookmark'){
                status = false;
            } else  if(this.type == 'delete'){
                status = false;
            }

            return status;
        },

        isBookmarked() {
            const message = this.message;

            // Cek apakah ada receiver dengan username yang sesuai dan bookmarked bernilai 1
            const receiverBookmark = message.receivers.some(element => {
                return element.username === this.loggedInUser.username && (element.bookmarked === 1 || element.bookmarked === '1');
            });

            const mentionBookmark = message.mentions.some(element => {
                return element.username === this.loggedInUser.username && (element.bookmarked === 1 || element.bookmarked === '1');
            });

            // Cek apakah message bookmarked bernilai 1 dan sender_id sesuai dengan username pengguna yang masuk
            return ((message.bookmarked === 1 || message.bookmarked === '1') && message.sender_id === this.loggedInUser.username) || receiverBookmark || mentionBookmark;
        },
            
        isRead(){
            let status = false;

            if(this.type == 'inbox'){
                let receivers = this.message.receivers;

                receivers.forEach(element => {
                    if(element.username === this.loggedInUser.username && (element.read === 1 || element.read === '1')){
                        status = true;
                    }
                });
            } else if(this.type == 'outbox'){
                status = true;
            } else  if(this.type == 'mention'){
                let mentions = this.message.mentions;

                mentions.forEach(element => {
                    if(element.username === this.loggedInUser.username && (element.read === 1 || element.read === '1')){
                        status = true;
                    }
                });
            } else  if(this.type == 'bookmark'){
               status = true;
            }

            return status;
        }
    },

    components : {
        messageComment,
        commentInput
    }
    
};
</script>

<style lang="scss" scoped>
 ::v-deep .mention{
    color: #1F76C2;
    padding: 0px 2px;
    background: #CCE5FF;
 }
</style>