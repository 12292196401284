<template>
<div class="bg-base w-full h-screen overflow-hidden">
    <v-loading v-if="isloading"></v-loading>
    <v-snackbar></v-snackbar>
    <div class="flex w-full h-[100vh]">
        <Transition name="slide-fade">
            <div class="w-[80%] shadow  h-screen fixed top-0 left-0 z-40 block md:hidden" v-if="showmenu">
                <div class="w-full relative h-screen">
                    <mdicon name="menu" class="top-4 right-4 absolute cursor-pointer text-gray-100" @click="showmenu = false"></mdicon>
                    <sidebar></sidebar>
                </div>
            </div>
        </Transition>
        <Transition name="fade">
            <div v-if="showmenu" class="fixed md:hidden top-0 left-0 bg-black-overlay w-full h-screen z-30" @click="showmenu = false">

            </div>
        </Transition>
        <div class="overflow-hidden w-full">
            <navigation @menu="showmenu = !showmenu" @info="userinfo = !userinfo" @notif="handleNotif" @message="handleMessage"></navigation>
            <div class="flex">
                <div class="hidden md:block">
                    <sidebar></sidebar>
                </div>
                <div class="w-full h-[calc(100vh-50px)] overflow-y-auto">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>

    <userinfoVue @logout="handleLogout" v-model="userinfo" @change_password="handleChangePassword" @payslip_password="handleChangePayslip" @profile="handeProfile" @notif="handleNotif"></userinfoVue>
    <messageVue v-model="messageDialog"></messageVue>

    <v-dialog v-model="passwordDialog">
        <v-card class="w-[90vw] md:w-[30vw]">
            <template #card-title>
                <div class="flex justify-between items-center">
                    <div class="flex gap-1 text-gray-700 justify-start items-center mb-4">
                        <mdicon name="key-variant" size="20"></mdicon>
                        <div class="text-lg">Change Password</div>
                    </div>
                    <v-btn icon="close" size="small" @click="handleChangePassword"></v-btn>
                </div>
            </template>
            <template #card-body>
                <div>
                    <v-text-field class="mb-2" :type="show_reset_password ? 'text' : 'password'" v-model="fchange_password.curent_password" label="Current password"></v-text-field>
                    <v-text-field class="mb-2" :type="show_reset_password ? 'text' : 'password'" v-model="fchange_password.new_password" label="New password"></v-text-field>
                    <v-text-field class="mb-2" :type="show_reset_password ? 'text' : 'password'" v-model="fchange_password.confirm_password" label="Confirm password"></v-text-field>
                    <v-checkbox v-model="show_reset_password" label="Show password"></v-checkbox>
                </div>
            </template>
            <template #card-action>
                <div class="w-full flex justify-end items-center gap-2">
                    <v-btn type="danger" @click="handleChangePassword">Cancel</v-btn>
                    <v-btn type="primary" :disabled="disableChangePassword" @click="updatepassword">Update</v-btn>
                </div>
            </template>
        </v-card>
    </v-dialog>

    <v-dialog v-model="payslipDialog">
        <v-card class="w-[90vw] md:w-[30vw]">
            <template #card-title>
                <div class="flex justify-between items-center">
                    <div class="flex gap-1 text-gray-700 justify-start items-center mb-4">
                        <mdicon name="key-variant" size="20"></mdicon>
                        <div class="text-lg">Change Payslip Password</div>
                    </div>
                    <v-btn icon="close" size="small" @click="handleChangePayslip"></v-btn>
                </div>
            </template>
            <template #card-body>
                <div>
                    <v-text-field class="mb-2" :type="show_payslip_password ? 'text' : 'password'" v-model="payslip_password.new_password" label="New password"></v-text-field>
                    <v-text-field class="mb-2" :type="show_payslip_password ? 'text' : 'password'" v-model="payslip_password.confirm_password" label="Confirm password"></v-text-field>
                    <v-checkbox v-model="show_payslip_password" label="Show password"></v-checkbox>
                </div>
            </template>
            <template #card-action>
                <div class="w-full flex justify-end items-center gap-2">
                    <v-btn type="danger" @click="handleChangePayslip">Cancel</v-btn>
                    <v-btn type="primary" :disabled="disablePayslipPassword" @click="updatepayslip">Update</v-btn>
                </div>
            </template>
        </v-card>
    </v-dialog>

    <!-- bulk delete -->
    <v-dialog v-model="logoutDialog">
        <v-card class="w-[90vw] md:w-[30vw]">
            <template #card-title>
                <div class="flex gap-1 text-gray-700 justify-start items-center">
                    <mdicon name="alert-circle-outline" size="25"></mdicon>
                    <div class="text-xl">Confirm</div>
                </div>
            </template>
            <template #card-body>
                <div class="py-4 text-[14px] text-gray-700">Are you sure you want to logout from this app?</div>
            </template>
            <template #card-action>
                <div class="flex justify-end items-center gap-2">
                    <v-btn type="danger" @click="handleLogout">Cancel</v-btn>
                    <v-btn type="primary" @click="logout">Confirm</v-btn>
                </div>
            </template>
        </v-card>
    </v-dialog>
    <!-- bulk delete -->

    <v-dialog v-model="profileDialog">
        <myProfileVue @close="handeProfile"></myProfileVue>
    </v-dialog>

    <vSlideDialogVue v-model="notifDialog">
        <notificationVue @close="handleNotif"></notificationVue>
    </vSlideDialogVue>

</div>
</template>

<script>
import messageVue from '@/components/message/message.vue'
import navigation from './navigation.vue';
import userinfoVue from './userinfo.vue';
import store from '@/store';
import sidebar from './sidebar.vue'
import SecureLS from "secure-ls"
import myProfileVue from '@/components/my-profile/my-profile.vue';
import vSlideDialogVue from './component/v-slide-dialog.vue';
import notificationVue from '@/components/notification/notification.vue';
// const  ls = new SecureLS({isCompression: false})
export default {
    name: 'ClientAdminLayout',

    data() {
        return {
            notifDialog: false,
            messageDialog: false,
            userinfo: false,
            passwordDialog: false,
            profileDialog: false,
            jobDialog: false,
            payslipDialog: false,
            logoutDialog: false,
            smallMenu: false,
            showmenu: false,
            isloading: false,
            filter: store.state.filterText,
            fchange_password: {
                curent_password: '',
                new_password: '',
                confirm_password: '',
            },
            payslip_password: {
                new_password: '',
                confirm_password: '',
            },
            show_reset_password: false,
            show_payslip_password: false,

        };
    },

    mounted() {

    },

    methods: {

        logout() {
            this.isloading = true;
            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env
            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/logout',{}, { headers : headers } ).then(() => {
                this.$router.push("/login");
                localStorage.clear()
            }).catch((error) => {

            }).finally(() => {
                this.isloading = false;
            })
        },
        handeProfile() {
            if (this.profileDialog) {
                this.profileDialog = false
            } else {
                this.profileDialog = true
            }

            this.userinfo = false;
        },

        handleMessage() {
            if (this.messageDialog) {
                this.messageDialog = false
            } else {
                this.messageDialog = true
            }

            this.userinfo = false;
        },

        handleNotif() {
            if (this.notifDialog) {
                this.notifDialog = false;
            } else {
                this.notifDialog = true;
            }

            this.userinfo = false;
        },
        handleChangePassword() {
            if (this.passwordDialog) {
                this.passwordDialog = false;
                this.$nextTick(() => {
                    this.fchange_password = {
                        curent_password: '',
                        new_password: '',
                        confirm_password: '',
                    }
                });
            } else {
                this.passwordDialog = true;
            }

            this.userinfo = false;
        },
        handleChangePayslip() {
            if (this.payslipDialog) {
                this.payslipDialog = false;

                this.$nextTick(() => {
                    this.payslip_password = {
                        curent_password: '',
                        new_password: '',
                        confirm_password: '',
                    }
                });
            } else {
                this.payslipDialog = true;
            }

            this.userinfo = false;
        },
        handleLogout() {
            if (this.logoutDialog) {
                this.logoutDialog = false;
            } else {
                this.logoutDialog = true;
            }

            this.userinfo = false;
        },

        updatepassword() {
            this.isloading = true;
            let formData = new FormData();
            formData.append('id', this.loggedInUser.id);
            formData.append('current_password', this.fchange_password.curent_password);
            formData.append('new_password', this.fchange_password.new_password);
            formData.append('confirm_password', this.fchange_password.confirm_password);

            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/user/password/update', formData, { headers : headers }).then((response) => {
                let rs = response.data.message;
                this.$notify({
                    type: "success",
                    title: 'Success',
                    text: rs,
                });
                this.handleChangePassword();
            }).catch((error) => {
                let rs = error.response.data.message;
                this.$notify({
                    type: "error",
                    title: 'Error',
                    text: rs,
                });
                this.showerror = true
                this.errordata = error.response.data

            }).finally(() => {
                this.isloading = false;

            });
        },
        updatepayslip() {
            this.isloading = true;
            let formData = new FormData();
            formData.append('id', this.loggedInUser.employee.id);
            formData.append('new_password', this.payslip_password.new_password);
            formData.append('confirm_password', this.payslip_password.confirm_password);

            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'X-API-KEY': this.$apiKey // Ambil dari .env

            }
            this.axios.defaults.headers = { 'Authorization': `Bearer ${this.token}` }
            this.axios.post('api/employee/payslip_password/update', formData,  { headers : headers }).then((response) => {
                let rs = response.data.message;
                this.$notify({
                    type: "success",
                    title: 'Success',
                    text: rs,
                });
                this.handleChangePayslip();
            }).catch((error) => {
                let rs = error.response.data.message;
                this.$notify({
                    type: "error",
                    title: 'Error',
                    text: rs,
                });
                this.showerror = true
                this.errordata = error.response.data

            }).finally(() => {
                this.isloading = false;

            });
        },
    },

    components: {
        sidebar,
        userinfoVue,
        myProfileVue,
        vSlideDialogVue,
        notificationVue,
        navigation,
        messageVue
    },
    computed: {

        disableChangePassword() {
            if (this.passwordDialog) {
                let new_password = this.fchange_password.new_password;
                let confirm_password = this.fchange_password.confirm_password;

                return new_password != confirm_password
            }

            return false
        },
        disablePayslipPassword() {
            if (this.payslipDialog) {
                let new_password = this.payslip_password.new_password;
                let confirm_password = this.payslip_password.confirm_password;

                return new_password != confirm_password
            }

            return false
        }
    },
    watch: {
        $route(to, from) {
            if (to != from) {
                this.showmenu = false;
                this.$nextTick(function () {
                    this.filter = ''
                });
            }
        }
    }

};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
    transition-delay: .2s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
    transition-delay: .2s;
}

.slide-fade-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(-1000px);
}
</style><style lang="scss">
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a4a4a4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #616161;
}
</style>
